import React, { useState } from 'react';

import { Typography, Button, Collapse, Grid, Box } from '@material-ui/core';
import { ExpandLess, ExpandMore, Add } from '@material-ui/icons';

import useGlobalAppStyles from '../../config/styles';

import { EduClassSection } from '../../models/class';
import LessonCard from '../lesson-card';
import EduDialog from '../dialog';

import locale from '../../config/languages/locale';
import SectionForm from '../section-form';
import LessonForm, { FormLesson } from '../lesson-form';
import { useAppSelector } from '../../redux/hooks';
import EduLoader from '../loader';

export type CollapseProps = {
    sections: EduClassSection[];
    isEdit: boolean;
    addSection: (e: any) => void;
    addLesson: (e: any) => void;
};

const CollapseSection = (props: CollapseProps) => {

    const { sections, isEdit, addSection, addLesson } = props;
    const [collapseControl, setCollapseControl] = useState<{ [key: string]: boolean }>({});
    const styles = useGlobalAppStyles();
    const [adding, setAdding] = useState(false);
    const [dialogLessonOpen, setDialogLessonOpen] = useState<number>();

    const isLoading: boolean = useAppSelector(state => state.loader.isLoading);

    const handleCollapse = (name: string) => {
        const current = collapseControl[name];
        if (current) {
            setCollapseControl({
                ...collapseControl,
                [name]: !current
            })
        } else {
            setCollapseControl({
                ...collapseControl,
                [name]: true
            })
        }
    };

    const onAddLesson = (form: FormLesson) => {
        addLesson({
            ...form,
            id: dialogLessonOpen
        });
    };

    const onOpenLessonDialog = (section: EduClassSection) => {
        setDialogLessonOpen(section.id);
    };

    const onSection = (): JSX.Element => {
        if (isLoading) {
            return <EduLoader label='loading'></EduLoader>
        } else {
            return <SectionForm onAdd={addSection} />
        };
    }

    return <>
        <Box sx={{
            width: '80%',
            marginLeft: '10%',
            marginTop: '10vh',
            marginBottom: '10vh'
        }}>
            <EduDialog
                open={adding}
                onClose={() => setAdding(false)}
                title={locale.classDetail.addSection}
                maxWidth={'sm'}
                fullWidth
                customContent={<SectionForm onAdd={addSection} />}
            />

            <EduDialog
                open={!!dialogLessonOpen}
                onClose={() => setDialogLessonOpen(undefined)}
                title={locale.classDetail.addLesson}
                maxWidth={'sm'}
                fullWidth
                customContent={<LessonForm sectionId={dialogLessonOpen} onAdd={onAddLesson} />}
            />


            <Grid container>
                <Grid item xs={6}>
                    <Typography
                        variant='h4'
                        className={`${styles.mainTitles} ${styles.marginBottom}`}
                    >
                        Sections

                    </Typography>
                </Grid>
                <Grid item xs={6} className={styles.editButtonTitle}>
                    {isEdit && <Button
                        color='primary'
                        variant='contained'
                        onClick={() => setAdding(true)}
                    >
                        <Add />
                    </Button>}
                </Grid>
            </Grid>
            {sections.map(entry =>

                <Grid container key={`${entry.name}-grid-collapse-key`}>

                    <Grid
                        item
                        xs={12}
                        className={styles.sections}
                    >

                        <Typography variant='h6'> {entry.name} </Typography>

                        <Button
                            onClick={(e: any) => handleCollapse(entry.name)}
                            startIcon={collapseControl[entry.name] ? <ExpandLess /> : <ExpandMore />}
                        />

                    </Grid>

                    <Collapse in={collapseControl[entry.name]}>

                        <LessonCard
                            isEdit={isEdit}
                            onSectionId={() => onOpenLessonDialog(entry)}
                            section={entry} />

                    </Collapse>

                </Grid>

            )}
        </Box>
    </>
}

export default CollapseSection;