import Pagination from '../../models/pagination';


export const updatePagination = (input: Pagination): Pagination => {
    const updated = { } as Pagination;
    updated.limit = input.limit;
    updated.start = input.limit * (input.page -1);
    updated.page = input.page;
    updated.totalItems = input.totalItems;
    return updated
}