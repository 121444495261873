import axios, { AxiosRequestConfig } from "axios";
import { Environment } from "../config/environments";
import { BaseURL } from "../config/url";
import { getUniversalCookies} from '../config/cookieFile/cookies';
import { CookieName } from "../config/cookie";

export enum EduStatus {
  SUCCESS = "success",
  FAILURE = "failure",
  ERROR = "error",
}

export interface EduResponse<T> {
  status: EduStatus;
  message: string;
  payload: T;
}

export const ADDRESS_PATH_PARAM = "{address}";

const API_PREFIX = "/v1/";

const getURL = () =>
  process.env.NODE_ENV === Environment.PROD ? BaseURL.PROD : BaseURL.DEV;

const cookie = getUniversalCookies().get(CookieName.COOKIE);
  
const configuration: AxiosRequestConfig = {
  baseURL: `${getURL()}${API_PREFIX}`,
  /* timeout: 5000, */
  ...cookie && cookie.length > 0 && {headers: {'Bearer': `${cookie}`}},
  validateStatus: () => true,
};

export const compose = (url: string, param: string, value: string) =>
  url.replace(param, value);

export const xhr = axios.create(configuration);


//updates|cleans headers if there's a cookie stored
export const setAuthToken = async(token?: string) => {
  if (token) {
    xhr.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete xhr.defaults.headers.common.Authorization;
  }
  return !!xhr.defaults.headers.common.Authorization
};