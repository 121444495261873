import { call, put, takeLatest } from 'redux-saga/effects';

import { EduResponse, xhr } from '../../../api';
import { setIsModalLoading } from '../../slices/loader';

import { isTransactionFinish, postTransaction } from '../../slices/transactions';

type TransactionResponse = {
    data: EduResponse<any>
}

export default function* transactionsSagas() {

    //POST TRANSACTION
    yield takeLatest(postTransaction, function* ({payload}) {
       
        try {
            const ownerSlotId = payload.id;
            const data = {
                buyerId: payload.buyer,
                hash: payload.transactionHash,
                blockHash: payload.blockHash
            }
            // eslint-disable-next-line 
            const res: TransactionResponse = yield call(xhr.put, `/resources/${ownerSlotId}/buy`, data);
            yield put(setIsModalLoading(false));
            yield put(isTransactionFinish(true))

        }catch(err){
            console.log('post transaction error', err)
        }

    })
}