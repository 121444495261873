import { Grid, Dialog, Box, Typography, Button, Chip, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";
import { maticLogo } from "../../assets/images";

import EduTransaction from "../../models/transaction";


import locale from "../../config/languages/locale";
import useGlobalAppStyles from "../../config/styles";
import EduClass from "../../models/class";

export interface ResourceModalProps {
    resource?: EduTransaction;
    resourceUploaded?: EduClass;
    open: boolean;
    onClose: () => void;
    onBuy: () => void;
};

const ResourceDialog = (props: ResourceModalProps) => {

    const { resource, open, resourceUploaded, onClose, onBuy } = props;
    const styles = useGlobalAppStyles();

    return <>
    {resource &&
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth='sm'
            PaperProps={{
                className: `${styles.dialog}`
            }}
        >
            <DialogTitle disableTypography className={styles.dialogTitle} />

            <DialogContent className={styles.dialog} >  
            <Typography variant='h5'> {resource?.class ? resource.class.info?.title : resourceUploaded?.info?.title} </Typography>
                
                <Box sx={{ marginTop: '3%', marginBottom: '10%'}}>

                <Typography variant='body1'> 
                    {resource?.class ? resource.class.info?.description : resourceUploaded?.info?.description} 
                </Typography>

                    <Grid container className={`${styles.dialogRow} ${styles.priceRow}`}>

                        <Grid item xs={12} sm={3}>
                            <Typography variant='h6'>
                                {locale.resource.price}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={9}>
                            <Typography variant='h5' className={styles.mainTitles}>
                             {resource?.class ? resource.class.info?.price : resourceUploaded?.info?.price} 
                            <img alt='Matic' title='Matic' className={styles.colorCurrencyLogo} src={maticLogo} />
                            </Typography>
                        </Grid>

                    </Grid>

                </Box>

                <Grid container spacing={1} className={styles.dialogRow}>
                    
                    <Grid item xs={12} sm={3}>
                        <Typography variant='h6'> {locale.resource.category} </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                    <Chip 
                        key={`${resource?.class ? resource.class.info?.category.name :  resourceUploaded?.info?.category.name}-chip-key`} 
                        className={`${styles.chipRow} ${styles.chip}`} 
                        label={resource.class.info?.category.name} />
                       {/*  {resource.class.info.category.map(entry => (
                            <Chip key={`${entry}-chip-key`} className={`${styles.chipRow} ${styles.chip}`} label={entry} />
                        ))} */}
                    </Grid>

                </Grid>

                <Grid container spacing={1} className={styles.dialogRow}>
                    
                    <Grid item xs={12} sm={3}>
                        <Typography variant='h6'> {locale.resource.author} </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                    <Chip label={resource?.class ? resource.class.owner.username : "Anonymous"} className={styles.chip} />
                    </Grid>

                </Grid>

                <Grid container spacing={1} className={styles.dialogRow}>
                   
                    <Grid item xs={12} sm={3}>
                        <Typography variant='h6'> {locale.resource.seller} </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Chip label={resource.seller?.username || ''} className={styles.chip} />
                        </Grid>

                </Grid>
                
                {/* <Grid container spacing={1} className={styles.dialogRow}>
                    
                    <Grid item xs={12} sm={3}>
                        <Typography variant='h6'> {locale.resource.language} </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Chip label={resource.language} className={styles.chip} />
                    </Grid>

                </Grid> */}

            </DialogContent>

            <DialogActions className={styles.dialogActions}>
                
                <Button
                    className={styles.buttons}
                    onClick={onBuy}
                >
                    {locale.resource.buy}
                </Button>

            </DialogActions>

        </Dialog>
    }
    </>
}

export default ResourceDialog;