import { makeStyles } from '@material-ui/core';

const useHomeStyles = makeStyles((theme) => ({
    resourcesContainer: {
        width: '90vw',
        marginTop: '5vh',
        marginLeft: '5vw',
        minHeight: '100vh'
    },
    emphatizedText: {
        color: '#C5423D'
    },
    secondaryText: {
        color: '#5B584C'
    },
    
}));

export default useHomeStyles;