import {makeStyles} from "@material-ui/core";

const useWalletStyle = makeStyles((theme) => ({
    title: {
        textAlign: 'center'
    },
    faq: {
        padding: theme.spacing(2),
        textAlign: 'center',
        borderTop: '1px solid #d2d2d2'
    },
    faqBtn: {
        marginTop: theme.spacing(1)
    }
}));


export default useWalletStyle;
