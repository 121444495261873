import React, { useState } from 'react';
import { Avatar, Typography, Button, Box, Grid, Divider, Card, TextField, FormGroup,  Badge } from '@material-ui/core';
import { AddCircle, Create, Save, Undo, Edit, Close } from '@material-ui/icons';

import EduUser from '../../models/user';
import useGlobalAppStyles from '../../config/styles';
import locale from '../../config/languages/locale';

import { testImage } from '../../assets/images';
//TODO - replace with image placeholder
export type ProfileCardProps = {
    user: EduUser;
    addCourse: () => void;

}

const ProfileCard = (props: ProfileCardProps) => {
    const { user, addCourse } = props;
    const [fields, setFields] = useState({username: '', bio: '', profilePicture: ''});
    const [file, setFile] = useState<File>();
    const styles = useGlobalAppStyles();
    const [editing, setEditing] = useState<boolean>(false);

    const handleChange = (e: any) => {
        const key = e.target.name;
        const value = e.target.value;
        console.log(key, value)
        setFields({
            ...fields,
            [key]: value
        });
    };

    const handleImage = (e: any) => {
        //hide warning till file is used
        console.log(file)
        console.log('handle image called - TBA')
        //img upload logic

        //replace empty string with BE img url
        setFields({
            ...fields,
            profilePicture: ''
        });
    };

    const onSave = () => {
        console.log('on save called - TBA')
        //call be
    };

    const onCancel = () => {
        setFields({
            username: user.username || '',
            bio: user.bio || '',
            profilePicture: user.profilePicture || ''
        });
        setFile(undefined);
    }

    return <>
        <Box className={styles.profileContainer}>
            <Box className={styles.profileHeading}>
                <Typography
                    variant='h4'
                    className={styles.mainTitles}
                >
                    {editing ? locale.profile.editing : locale.profile.title}

                </Typography>
                <Button
                    className={styles.buttons}
                    variant='contained'
                    onClick={() => setEditing(!editing)}
                >
                    {!editing && <Create />}
                    {editing && <Close />}
                </Button>
            </Box>

            <Grid container spacing={2} className={styles.profileCard}>

                <Grid item sm={12} md={5} className={`${styles.profileAvatarBox}`}>

                    {!editing && 
                        <Avatar
                            className={styles.profileAvatar}
                            alt='user-profile'
                            src={user.profilePicture || testImage}
                        />
                    }
                    {editing && 
                    <div>
                        <Badge
                            overlap='circular'
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            badgeContent={
                                
                                    <Avatar 
                                        className={styles.profileAvatarEdit} 
                                        onClick={() => handleImage}
                                    >
                                        <input style={{display: 'none'}} accept='image/*' type='file'/>
                                        <Edit/>
                                    </Avatar>
                                
                            }
                            
                        >
                            <Avatar
                                className={`${styles.profileAvatar} ${styles.greyFilter}`}
                                alt='user-profile'
                                src={user.profilePicture || testImage}
                            />
                        </Badge>
                    </div>
                    }
                </Grid>

                <Grid item sm={12} md={7} className={`${styles.profileTextBox}`}>

                    {!editing &&
                        <>
                            <Typography variant='h4'>{user.username || locale.profile.username}</Typography>
                            <Typography
                                variant='body1'
                                className={styles.userBio}
                            >
                                {user.bio || locale.profile.bio}
                            </Typography>
                        </>
                    }
                    {editing &&
                        <>
                            <Card className={styles.profileForm}>

                                <FormGroup>
                                    <Typography variant='h6'> Username </Typography>
                                    <TextField
                                        name='username'
                                        className={styles.profileInput}
                                        type='text'
                                        defaultValue={user.username || ''}
                                        variant='outlined'
                                        fullWidth
                                        onBlur={(e: any) => handleChange(e) }
                                    />

                                    <Typography variant='h6'> Bio </Typography>
                                    <TextField
                                        name='bio'
                                        type='text'
                                        multiline={true}
                                        defaultValue={user.bio || ''}
                                        variant='outlined'
                                        fullWidth
                                        onBlur={(e:any) => handleChange(e)}
                                    />
                                </FormGroup>
                                <Box className={styles.profileSave}>
                                    <Button
                                        variant='contained'
                                        onClick={onCancel}
                                        className={`${styles.altButtons} ${styles.marginRight}`}
                                        startIcon={<Undo />}
                                    >
                                        UNDO
                                    </Button>
                                    <Button
                                        variant='contained'
                                        onClick={onSave}
                                        startIcon={<Save />}
                                        className={`${styles.buttons}`}
                                    >
                                        SAVE
                                    </Button>
                                </Box>
                            </Card>


                        </>
                    }
                    <Divider className={styles.marginTop} />

                    <Box className={styles.marginTop}>
                        <Typography
                            variant='h5'
                            className={`${styles.mainTitles} ${styles.marginTop}`}
                        >
                            {locale.profile.teach}
                        </Typography>

                        <Button
                            className={styles.teachingButton}
                            onClick={addCourse}
                            variant='contained'
                            endIcon={<AddCircle />}
                        >
                            {locale.profile.button}
                        </Button>
                    </Box>
                </Grid>

            </Grid>

        </Box>
    </>
}

export default ProfileCard;

