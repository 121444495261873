import { createTheme } from "@material-ui/core";

export const theme = createTheme({
    palette: {
      primary: {
        main: '#D87C21',
      },
      secondary: {
        main: '#B81714',
      },
    },
  });