import { all, fork } from "@redux-saga/core/effects";
import resourcesSagas from "./courses";
import authSagas from "./auth";
import transactionsSagas from "./transactions";

const sagas = [
    resourcesSagas,
    authSagas,
    transactionsSagas
];

export default function* rootSaga() {
    yield all(sagas.map(saga => fork(saga)));
}