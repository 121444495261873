import {Button, Menu, MenuItem} from "@material-ui/core";
import {useAppDispatch} from "../../redux/hooks";
import React, {MouseEvent, useState} from "react";
import { useCookies } from "react-cookie";
import { CookieName } from "../../config/cookie";
import { setAuthToken } from "../../api";
import { reset } from "../../redux/slices/auth";
import {reset as resourcesReset} from '../../redux/slices/courses';
import EduUser from "../../models/user";
import {Link} from 'react-router-dom';
import useGlobalAppStyles from "../../config/styles";
export interface ProfileOptionsProps {
    user: EduUser
    
}

export const ProfileOptions = (props : ProfileOptionsProps) => {

    const { user } = props;
    const [anchor, setAnchor] = useState<Element>()
   
    const [, , removeCookie] = useCookies();

    const onProfileOpen = (e: MouseEvent) => setAnchor(e.currentTarget)
    const onProfileClose = () => setAnchor(undefined)
    const styles = useGlobalAppStyles();

    const dispatch = useAppDispatch()

    const onLogout = () => {
        removeCookie(CookieName.COOKIE);
        setAuthToken();
        onProfileClose();
        dispatch(reset());
        dispatch(resourcesReset());
    }

    return (
        <div>
            <Button 
                aria-haspopup={true} 
                aria-controls={"profile-options"} 
                color="inherit" 
                variant={"outlined"}
                onClick={onProfileOpen}>{user.username}</Button>
            <Menu
                id="profile-options"
                keepMounted
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={onProfileClose}
            >
                
                <Link to='/profile' onClick={onProfileOpen} className={styles.optionsLinks}> Profile </Link>
                <MenuItem onClick={onProfileClose}>My account</MenuItem>
                <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
        </div>
    )
}
