import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import useGlobalAppStyles from "../../config/styles";

export interface LoaderProps {
    label?: string;
}

const EduLoader = (props: LoaderProps) => {
    const { label } = props;
    const styles = useGlobalAppStyles();

    return <>
        <Box className={styles.loader}>
            <Typography variant='h3' className={styles.loaderHeading}>{label}</Typography>
            <CircularProgress className={styles.loaderCircle}/>
        </Box>   
    </>

};

export default EduLoader;