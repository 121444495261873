import React from "react";
import {Link} from 'react-router-dom';
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import WalletSelector from "../wallet-selector";
import useNavbarStyles from "./style";
import { ProfileOptions } from "../profile-options";
import { AuthState } from "../../redux/slices/auth";
export interface NavbarProps {
  onMetamaskConnect: () => void;
  onLogoClick: () => void;
  auth: AuthState;
}

const Navbar = (props: NavbarProps) => {
  const {auth, onLogoClick, onMetamaskConnect} = props;
  const classes = useNavbarStyles();

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        
        <Typography variant="h6" className={`${classes.title} ${classes.hoover}`} onClick={onLogoClick}>
        <Link to='/' className={classes.title}> EduApp </Link>
        </Typography>
       
        {!auth.address && <WalletSelector connectors={{ onMetamaskConnect }} />}
        {auth.address && <ProfileOptions user={auth.user} />}
      </Toolbar>
    </AppBar>
  );
};

export default (Navbar);
