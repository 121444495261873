import { Box, Divider, Card, CardContent, CardActions, CardHeader, CardMedia, Button } from '@material-ui/core'
import { maticLogo } from '../../assets/images';

import EduTransaction from "../../models/transaction";

import useGlobalAppStyles from '../../config/styles/index';
import MenuListComposition from '../drop-down-edit';
import EduClass from '../../models/class';
import { getCoursesDetail, setIsEdit } from '../../redux/slices/courses';
import { useAppDispatch } from '../../redux/hooks';
import { useHistory, useLocation } from 'react-router';
import { AppRoute } from '../../models/app-route/AppRoute';
import { useEffect } from 'react';
import { useWeb3 } from '../../context/web3/hooks';
export interface ResourceCardProps {
    resource?: EduTransaction,
    resourceUploaded?: EduClass,
    classId?: number,
    isEdit: boolean,
    onClick: (e: any) => void
};

const ResourceCard = (props: ResourceCardProps) => {

    const styles = useGlobalAppStyles();
    const { resource, classId, isEdit, resourceUploaded, onClick } = props;

    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const web3 = useWeb3();

    useEffect(() => {
        console.log('RES', resourceUploaded)
    }, [])

    const onClassDetail = () => {
        
        if (resource?.classId) {
            history.push(`${AppRoute.CLASS_DETAIL}/${resource.classId}`)
        } else if (resourceUploaded?.id) {
            history.push(`${AppRoute.PROFILE}${AppRoute.CLASS_DETAIL}/${resourceUploaded.id}`)
        } else {
            alert('You need a class ID')
        }
        dispatch(setIsEdit(isEdit));
    }

    const formatPrice = (input: any): string => {
        const maticPrice = web3.fromWei(input);
        return maticPrice
    };

    const handleOffer = (): string | undefined => {
        if (resource?.class.ownerSlots && resource.class.ownerSlots.length > 0) {
            return `from ${formatPrice(resource.class.ownerSlots[0].offer)}`
        } else if (resourceUploaded?.ownerSlots && resourceUploaded.ownerSlots.length > 0) {
            return `from ${formatPrice(resourceUploaded?.ownerSlots[0].offer)}`
        } else return 'price pending'
    }

    return <>
        <Box>
            <Card
                variant='outlined'
                className={styles.cards}
            >
                
                <CardHeader 
                    title={resource?.class?.info ? resource.class.info.title.slice(0,16).toUpperCase() : resourceUploaded?.info?.title.slice(0,16).toUpperCase()} 
                    className={styles.cardHeader}
                />

                <CardContent className={styles.cardsBody} >    

                   <CardMedia 
                        component='img'
                        title ='image' 
                        src={resource?.class ? resource.class.info?.thumbnail : resourceUploaded?.info?.thumbnail} 
                        className={styles.cardImg}
                        
                    /> 

                </CardContent>

                <CardActions className={styles.cardsActions} >

                    <Box className={styles.priceBox}>
                    {handleOffer()}
                        <img alt='Matic' title='Matic' className={styles.currencyLogo} src={maticLogo} />

                    </Box>
                    <Divider orientation='vertical' light={false} flexItem />
                    <Button 
                        color='default'
                        onClick={onClassDetail}
                        >
                        Info
                    </Button>

{/*                     <MenuListComposition 
                        classId={classId} 
                        onClick={(e:any) => onClick(resource)}
                    />
 */}
                </CardActions>
            </Card>
        </Box>

    </>
};

export default ResourceCard;