import { createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import EduUser from '../../../models/user';
import { useAppSelector } from '../../hooks';

export interface AuthState {
    [key: string]: any;
    user: EduUser;
    exists: boolean | undefined;
    address: string;
    partial: string;
    nonce: number;
    jwt: string;
    logged: boolean;
}

const initialState: AuthState = {
    user: {
        id: 0,
        username: '',
        bio: '',
        profilePicture: '',
        address: '',
        createdClass: [],
        posts: [],
        selling: [],
        buying: []
    },
    address: '',
    partial: '',
    exists: undefined,
    nonce: 0,
    jwt: '',
    logged: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser:(state, action: PayloadAction<EduUser>) => {
            state.user = action.payload
        },
        setFromCookie: (state, action: PayloadAction<AuthState>) => {
            Object.keys(state).forEach(key => state[key] = action.payload[key])
            state.logged = true
        },
        setLogged: (state, action: PayloadAction<boolean>) => {
            state.logged = action.payload
        },
        setAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload
            state.partial = action.payload.slice(0, 16).concat('...')
        },
        setNonce: (state, action: PayloadAction<number>) => {
            state.nonce = action.payload
        },
        setExists: (state, action: PayloadAction<boolean>) => {
            state.exists = action.payload
        },
        setJWT: (state, action: PayloadAction<string>) => {
            state.jwt = action.payload
        },
        reset: (state) => {
            Object.keys(state).forEach(key => state[key] = initialState[key])
            state.exists = true
        }
        
    }
})

export const getNonce = createAction<{address: string}>('getNonce');
export const userExists = createAction<{currentAddress: string}>('userExists');
export const signIn = createAction<{address: string; signature: string}>('signIn')
export const signUp = createAction<{address: string}>('signUp');

export const { setUser, setNonce, setExists, setJWT, setAddress, reset, setFromCookie, setLogged } = authSlice.actions;

export const useAuth = () => useAppSelector(state => state.auth);

export default authSlice
