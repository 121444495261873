import React, { useState } from 'react';
import EduClass from "../../models/class";
import useGlobalAppStyles from '../../config/styles';
import { Grid, Typography, Chip, Button, Box } from '@material-ui/core';
import { maticLogo } from '../../assets/images/index';
import locale from '../../config/languages/locale';
import { Create } from '@material-ui/icons';
import EduDialog from '../dialog';
import CourseForm from '../create-course-form';
import { useAppSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { patchCourse } from '../../redux/slices/courses';
import { setCourseOwners } from '../../redux/slices/courses';
import BuyModal from '../buy-modal-content';

export type ClassCardProps = {
    viewClass: Partial<EduClass>;
    isEdit: boolean;
}

const ClassCard = (props: ClassCardProps) => {

    const { viewClass, isEdit } = props;
    const styles = useGlobalAppStyles();

    const [edit, setEdit] = useState<boolean>(false);
    const [buyDialog, setBuyDialog] = useState<boolean>(false);
    const address = useAppSelector(state => state.auth.address);
    const dispatch = useDispatch();
    //TODO: retrieve from redux
    const categories = ['a', 'b', 'c'];

    const patchClass = (form: any) => {
        const info = { info: { ...form, price: parseInt(form.price) }, owner: address };
        dispatch(patchCourse(info));
        setEdit(false);
        //should update underneath view
    };

    const onCloseDialog = () => {
        setBuyDialog(false);
    }

    const handleButton = () => {
        if (isEdit) {
            return <Button
                color='primary'
                variant='contained'
                onClick={() => isEdit ? setEdit(true) : setBuyDialog(true)}
            >
                <Create />
            </Button>
        } else if (viewClass.public && address === viewClass?.ownerId) {
            return <React.Fragment></React.Fragment>
        } else if (!isEdit) {
            return <Button
                color='secondary'
                variant='contained'
                onClick={() => isEdit ? setEdit(true) : setBuyDialog(true)}
            >
                Buy
            </Button>
        }
    }

    return <>

        {viewClass && viewClass.info && viewClass.id &&
            <EduDialog
                open={buyDialog}
                title={viewClass.info.title}
                maxWidth={'sm'}
                fullWidth={true}
                onClose={() => {
                    dispatch(setCourseOwners([]))
                    setBuyDialog(false)
                }
                }
                customContent={
                    <BuyModal
                        courseId={viewClass.id}
                        onCloseDialog={onCloseDialog}
                    />
                }
            />
        }

        <EduDialog
            open={edit}
            onClose={() => setEdit(false)}
            maxWidth={'md'}
            title={locale.classDetail.title}
            fullWidth
            customContent={
                <CourseForm
                    onCloseDialog={() => console.log('CLOSE')}
                    edit={true}
                    editCourse={viewClass}
                    onPatch={patchClass}
                    categories={categories}
                    onAdd={() => console.log('onadd')}
                />
            }
        />

        {viewClass.info ? <Box sx={{
            width: '80%',
            marginLeft: '10%',
            marginTop: '10vh',
            marginBottom: '10vh'
        }}>
            <Grid container spacing={3}>
            <Grid item xs={12} md={6}>

                <Grid item xs={12}>
                    <img
                        alt='class-cover'
                        src={viewClass.info.thumbnail}
                        className={styles.overViewImg}
                    />
                </Grid>

            </Grid>

            <Grid item xs={12} md={6}>
                <div className={styles.editButtonDiv}>

                    {handleButton()}

                </div>

                <Grid item xs={12} md={12}>
                    <Typography variant='h6'>{locale.shared.title}</Typography>
                    <Typography
                        variant='h4'
                        className={styles.mainTitles}
                    >
                        {viewClass.info.title}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography variant='h6'>{locale.shared.description}</Typography>
                    <Typography
                        variant='body1'
                    >
                        {viewClass?.info.description}
                    </Typography>
                </Grid>

                <Grid container spacing={3}>

                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant='h6'>{locale.shared.price}</Typography>
                        <Typography
                            variant='h6'
                        >
                            {viewClass.info.price}
                            <img src={maticLogo} alt='matic' className={styles.currencyLogo} />
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant='h6'>{locale.shared.category}</Typography>
                        <Chip label={viewClass.info.category.name} />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
            </Box> : <Box sx={{
            marginTop: 50,
            textAlign: 'center'
        }}>
            <Typography variant='h4'>{locale.shared.noClass}</Typography>
        </Box>}
    </>
}

export default ClassCard;