import React, { useState, useEffect } from 'react';
import EduClass from '../../models/class';
import useGlobalAppStyles from '../../config/styles';
import ClassCard from '../../components/class-card';
import CollapseSection from '../../components/collapse-section-container';
import { Box, Divider } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { postLesson, postSection, getCoursesDetail } from '../../redux/slices/courses';
import EduDialog from '../../components/dialog';
import { RouteProps, useParams } from 'react-router';
import { useCookies } from 'react-cookie';
import { useAuth } from '../../redux/slices/auth';
import { setAuthToken } from '../../api';
import EduLoader from '../../components/loader';
import DetailAlert from '../../components/detail-alert';
import PublishModal from '../../components/publish-modal-content';
import locale from '../../config/languages/locale';

function ClassDetail(prop: RouteProps) {

    const { location } = prop;

    //HOOKS
    const [cookies, setCookie] = useCookies();
    const styles = useGlobalAppStyles();
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const param: { id: string } = useParams();

    //SELECTORS
    const ownCourse: Partial<EduClass> | undefined = useAppSelector(state => state.courses.classDetail)
    const isLoading = useAppSelector(state => state.loader.isLoading);
    const uploaded = useAppSelector(state => state.courses.coursesUploaded);
    const userAddress = useAppSelector(state => state.auth.address);
    
    //LOCAL VARS && FUNCS
    const [publishForm, setPublishForm] = useState<boolean>(false);
    const [complete, setComplete] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const addSection = (form: any) => {
        dispatch(postSection({ section: form, classId: ownCourse?.id }))
    };

    const addLesson = (form: any) => {
        dispatch(postLesson({ lesson: { name: form.name, description: form.description }, sectionId: form.id }))
    };

    useEffect(() => {
        if (auth.jwt) {
            setAuthToken(auth.jwt).then(
                res => {
                    if (param) {
                        const classId = parseInt(param.id);
                        dispatch(getCoursesDetail({ classId }))
                    }
                })
                .catch(err => console.log(err))

        }
    }, []);

    //Check if user is owner of course and locally sets edit and completeness
    useEffect(() => {
        
        setIsEdit(userAddress === ownCourse?.ownerId && !ownCourse?.public);  

        if(ownCourse?.classSections &&
            ownCourse.classSections[0] &&
            ownCourse.classSections[0].lessons &&
            ownCourse.classSections[0].lessons[0] &&
            ownCourse.classSections[0].lessons[0].id
            ){
                setComplete(true)
            } else {
                setComplete(false)
            }
    },[ownCourse]);

    const onCloseDialog = () => {
        setPublishForm(false)
    }


    return <>
       
        {ownCourse && ownCourse.id &&
            <>
                <EduDialog
                    title={locale.classDetail.publishTitle}
                    open={publishForm}
                    maxWidth={'sm'}
                    fullWidth={true}
                    onClose={() => setPublishForm(false)}
                    customContent={<PublishModal onCloseDialog={onCloseDialog} classId={ownCourse.id}/>}
                />

                {isEdit && <DetailAlert
                    publish={() => setPublishForm(true)}
                    complete={complete}
                />}
            </>
        }
        {isLoading ? <EduLoader label='loading'/> : <Box>
            <ClassCard viewClass={ownCourse ? ownCourse : {}} isEdit={isEdit} />

            <Divider className={styles.divider} />

            {ownCourse?.classSections &&
                <CollapseSection
                    isEdit={isEdit}
                    sections={ownCourse.classSections}
                    addSection={addSection}
                    addLesson={addLesson} />}
        </Box>}
    </>
}

export default ClassDetail
