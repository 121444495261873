import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, TextField, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useGlobalAppStyles from "../../config/styles";
import locale from '../../config/languages/locale';
import { useWeb3 } from '../../context/web3/hooks';
import { useAppSelector } from '../../redux/hooks';
import { OwnerSlot, TransactionRecord } from '../../models/transaction';
import { useDispatch } from 'react-redux';
import { getCourseOwners, setIsPublishedDone } from '../../redux/slices/courses';
import { postTransaction, isTransactionFinish } from '../../redux/slices/transactions';
import EduLoader from '../loader';
import { setIsModalLoading } from '../../redux/slices/loader';

type BuyModalProps = {
    courseId: number;
    onCloseDialog: () => void;
}

const BuyModal = (props: BuyModalProps) => {

    const { courseId, onCloseDialog } = props;

    //HOOKS
    const styles = useGlobalAppStyles();
    const web3 = useWeb3();
    const dispatch = useDispatch();

    //SELECTORS
    const buyer: string = useAppSelector(state => state.auth.address);
    const options = useAppSelector(state => state.courses.selectedCourseOwners);
    const isModalLoading: boolean = useAppSelector(state => state.loader.isModalLoading);
    const isTransaction: boolean = useAppSelector(state => state.transactions.isTransactionFinish)

    //LOCAL VARS
    const [loading, setLoading] = useState<boolean>(true);
    const [courseOwner, setCourseOwner] = useState<OwnerSlot>();


    //INIT GET
    useEffect(() => {
        if (loading) {
            dispatch(getCourseOwners(courseId));
            setLoading(false);
        }
    }, [loading, options, courseId, dispatch]);


    const formatLabel = (input: OwnerSlot): string => {
        const maticPrice = web3.fromWei(input.offer);
        return maticPrice
    };


    const buyCourse = async () => {

        if (courseOwner) {
            setLoading(true);
            dispatch(setIsModalLoading(true))
            //Buy on Metamask
            await web3.sendTransaction(courseOwner.ownerId, buyer, courseOwner.offer)
                .then(res => {
                    console.log(res)
                    //Post on BE
                    const record: TransactionRecord = {
                        id: courseOwner.id,
                        buyer: buyer,
                        seller: courseOwner.ownerId,
                        classId: courseOwner.classId,
                        blockHash: res.blockHash,
                        transactionHash: res.transactionHash,
                        offer: courseOwner.offer
                    }
                    dispatch(postTransaction(record));
                })

                .catch(err => {
                    dispatch(setIsModalLoading(false))
                    console.log(err)
                })

                .finally(() => setLoading(false))
        }

    };

    const onClose = () => {
        onCloseDialog();
        dispatch(isTransactionFinish(false));
    }

    const handleTransaction = () => {
        if (isTransaction) {
            return <Box>
                <Typography>{locale.buyCourse.successful}</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5%'
                }}>
                    <Button
                        variant='outlined'
                        className={styles.buttons}
                        onClick={onClose}
                    >
                        {locale.shared.finish}
                    </Button>
                </Box>
            </Box>
        } else {
            return <Box>
                <Typography variant='h6'>{locale.buyCourse.title}</Typography>
                <Typography variant='body1' className={styles.marginTop}>{locale.buyCourse.info}</Typography>
                <Typography variant='body1' className={styles.marginTop}>{locale.buyCourse.infoTwo}</Typography>
                <Typography variant='body1' className={styles.marginTop}>{locale.buyCourse.infoThree}</Typography>

                <Grid container className={styles.courseOwnerSelect}>

                    <Grid item xs={6}>
                        <Typography variant='body2'> {locale.buyCourse.seller}</Typography>
                        <Typography variant='body1'><b>{courseOwner?.owner.username}</b></Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Autocomplete
                            className={styles.marginTop}
                            options={options ? options : []}
                            defaultValue={courseOwner}
                            onChange={(e: any, v: any) => { setCourseOwner(v) }}
                            getOptionLabel={(option: OwnerSlot) => formatLabel(option)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    name='courseOwner'
                                    /* variant='outlined' */
                                    label={locale.buyCourse.price}
                                    required
                                />}
                        />
                    </Grid>

                </Grid>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5%'
                }}>
                    <Button
                        variant='outlined'
                        className={styles.buttons}
                        onClick={() =>
                            buyCourse()
                        }
                        disabled={courseOwner === undefined}
                    >
                        {locale.resource.buy}
                    </Button>
                </Box>
                <Typography variant='caption'>{locale.buyCourse.disclaimer}</Typography>
            </Box>
        }
    };

    return <>
        <Box sx={{ padding: '5%' }}>

            <>
                {isModalLoading ? <EduLoader label='processing'></EduLoader> : handleTransaction()}
            </>
        </Box>
    </>
};

export default BuyModal