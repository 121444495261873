import { EduClassSection } from "../../models/class";
import useGlobalAppStyles from "../../config/styles";
import { Grid, Typography, Box } from '@material-ui/core';
import locale from "../../config/languages/locale";
import LessonList from "../lesson-list";

type LessonCardProps = {
    isEdit: boolean;
    section: EduClassSection;
    onSectionId: (value: EduClassSection) => void;
}
const LessonCard = (props: LessonCardProps) => {
    const { section, isEdit, onSectionId } = props;
    const styles = useGlobalAppStyles();

    return <>
        <Box sx={{
            marginTop: '5vh',
            marginLeft: '1vw',
            marginBottom: '5vh',
            width: '80vw'
        }}
        >
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography variant='body1' className={styles.mainTitles}>{locale.shared.section} {locale.shared.number}</Typography>
                    <Typography variant='h6'>{section.order}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body1' className={styles.mainTitles}>{locale.shared.name}</Typography>
                    <Typography variant='h6'>{section.name}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1' className={styles.mainTitles}>{locale.shared.description}</Typography>
                    <Typography variant='body1'>{section.description}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <LessonList
                        isEdit={isEdit}
                        section={section}
                        onSectionId={onSectionId}
                        lessons={section.lessons} />
                </Grid>
            </Grid>
        </Box>
    </>
};

export default LessonCard;