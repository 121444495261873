import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Grid, Box, AppBar, Tabs, Tab } from '@material-ui/core';
import ProfileCard from '../../components/profile-card';
import EduDialog from '../../components/dialog';
import locale from '../../config/languages/locale';
import CourseForm from '../../components/create-course-form';
import ResourceCard from '../../components/resource-card';
import EduTransaction from '../../models/transaction';
import useGlobalAppStyles from '../../config/styles';
import EduWarning from '../../components/warning/';
import ResourceDialog from '../../components/resource-dialog';
import { getCoursesPurchased, postCourse, getCoursesUploaded } from '../../redux/slices/courses';
import EduClass from '../../models/class';
import { TabPanel } from '../../components/tab-panel';
import EduLoader from '../../components/loader';
import { setAuthToken } from '../../api';


const Profile = () => {
    const [create, setCreate] = useState(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [detailCourse, setDetailCourse] = useState<EduTransaction>();
    const [, setDetailCourseClass] = useState<EduClass>();
    const [value, setValue] = React.useState(0);

    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);
    const categories = useAppSelector(state => state.courses.categories.map(entry => entry.name));
    const address = useAppSelector(state => state.auth.address);
    const isLoading = useAppSelector(state => state.loader.isLoading);
    const styles = useGlobalAppStyles();


    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        if (auth.jwt) {
            setAuthToken(auth.jwt).then(
                res => {
                    dispatch(getCoursesUploaded());
                    dispatch(getCoursesPurchased());
                }
            )
                .catch(err => console.log(err))
        }

    }, [])

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const expandResource = (resource?: EduTransaction, resourceClass?: EduClass) => {
        if (resource) {
            setDetailCourse(resource);
            toggleModal();
        } else if (resourceClass) {
            setDetailCourseClass(resourceClass);
            toggleModal();
        }
    };

    //TODO CALL 
    // const ownCourses: EduTransaction[] = mockTransactions.payload.data;
    //const ownCourses: EduTransaction[] = [];
    const ownCourses: EduClass[] = useAppSelector(state => state.courses.coursesUploaded);
    const purchasedCourses: EduTransaction[] = useAppSelector(state => state.courses.coursesPurchased);


    const uploadCourse = (form: any) => {
        dispatch(postCourse({ info: { ...form, price: parseInt(form.price) }, owner: address }));
    };

    const onCloseDialog = () => {
        setCreate(false);
    }


    return <>
        <EduDialog
            open={create}
            title={locale.createCourse.title}
            customContent={
                <CourseForm
                    onCloseDialog={onCloseDialog}
                    onAdd={uploadCourse}
                    categories={categories} />}
            maxWidth={'md'}
            fullWidth
            onClose={() => setCreate(false)}
        />

        <ResourceDialog
            resource={detailCourse}
            open={modalOpen}
            onClose={toggleModal}
            onBuy={() => console.log('clicked buy')}
        />

        <ProfileCard
            user={auth.user}
            addCourse={() => setCreate(true)}
        />

        <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Uploaded" {...a11yProps(0)} />
                <Tab label="Purchased" {...a11yProps(1)} />
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
            {isLoading ? <EduLoader label='loading'></EduLoader> : <Box>
                {ownCourses?.length > 0 &&
                    <Box className={styles.profileContainer}>
                        <Grid
                            container
                            spacing={6}
                            className={styles.resourceContainer}
                        >
                            {ownCourses.map((entry) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={`${entry.id}-profile-card`}>
                                    <ResourceCard
                                        classId={entry.id}
                                        resourceUploaded={entry}
                                        isEdit={true}
                                        onClick={() => expandResource(undefined, entry)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                }
                {ownCourses?.length === 0 &&
                    <EduWarning message={locale.profile.nodata} />
                }
            </Box>}
        </TabPanel>
        <TabPanel value={value} index={1}>
            {isLoading ? <EduLoader label='loading'></EduLoader> : <Box>
                {purchasedCourses?.length > 0 &&
                    <Box className={styles.profileContainer}>
                        <Grid
                            container
                            spacing={6}
                            className={styles.resourceContainer}
                        >
                            {purchasedCourses.map((entry) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={`${entry.id}-profile-card`}>
                                    <ResourceCard
                                        classId={entry.classId}
                                        resource={entry}
                                        isEdit={false}
                                        onClick={() => expandResource(entry, undefined)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                }
                {purchasedCourses?.length === 0 &&
                    <EduWarning message={locale.profile.nopurchase} />
                }
            </Box>}
        </TabPanel>


    </>
};

export default Profile;