import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import useWalletStyle from "./style";

export interface WalletSelectorProps {
  connectors: {
    onMetamaskConnect: () => void;
  };
}

const WalletSelector = ({ connectors }: WalletSelectorProps) => {
  const classes = useWalletStyle();

  const [open, setOpen] = useState<boolean>(false);

  const onWalletOpen = () => setOpen(true);
  const onWalletClose = () => setOpen(false);

  const onMetamaskConnect = async () => {
    onWalletClose();
    await connectors.onMetamaskConnect();
  };

  return (
    <div>
      <Button color="inherit" onClick={onWalletOpen}>
        Connect
      </Button>
      <Dialog open={open} onClose={onWalletClose}>
        <DialogTitle className={classes.title}>Connect to wallet</DialogTitle>
        <List>
          <ListItem button={true} onClick={onMetamaskConnect}>
            <ListItemAvatar>
              <Avatar
                alt={"Metamask Icon"}
                src={
                  "https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg"
                }
              />
            </ListItemAvatar>
            <ListItemText primary={"Metamask"} />
          </ListItem>
        </List>
        <Box className={classes.faq}>
          <Typography>Haven't got a crypto wallet yet?</Typography>
          <Button
            className={classes.faqBtn}
            color={"primary"}
            variant={"contained"}
          >
            How to connect
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default WalletSelector;
