import { xhr, EduResponse } from '../../../api';
import {put, takeLatest, call} from 'redux-saga/effects';
import {setUser, getNonce,userExists, setNonce, setExists, signIn, signUp, setJWT, setAddress, setLogged} from '../../slices/auth';
import mockedUser from '../../../assets/mocked-data/user.json';

type SigninResponse = { 
    data: EduResponse<{jwt: string, signature: {address: string}}>
};

type SignupResponse = {
    data: EduResponse<{address: string, nonce: any}>
}
type ExistsResponse = {
    data: EduResponse<{exists: boolean}>
}
type NonceResponse = {
    data: EduResponse<{nonce: number, address: string}>
}

export default function* authSagas() {

    //GET NONCE
    yield takeLatest(getNonce, function*({payload}) {
        try {
            const address = payload.address;
            const response: NonceResponse = yield call(xhr.get, `/user/${address}/nonce`);
            yield put(setNonce(response.data.payload.nonce));
            yield put(setAddress(response.data.payload.address));
        } catch (err) {
            console.log('USER NONCE ERROR', err)
        }
    });

    //EXISTS
    yield takeLatest(userExists, function*({payload}) {
        try {
            const currentAddress = payload.currentAddress;
            const response: ExistsResponse = yield call(xhr.get, `/user/${currentAddress}/exists`)
            yield put(setExists(response.data.payload.exists));
        } catch (err) {
            console.log('USER EXISTS ERROR', err)
        }

    });
    
    //SIGN IN
    yield takeLatest(signIn, function*({payload}) {
        try {
            const address = payload.address;
            const signature = payload.signature;
            const response: SigninResponse = yield call(xhr.post, `/user/${address}/sign-in`, {signature: signature} )
            const jwt = response.data.payload.jwt;
            const add = response.data.payload.signature.address;
            yield put(setAddress(add));
            yield put(setLogged(true));
            yield put(setUser(mockedUser));
            yield put(setJWT(jwt));
        } catch(err) {
            console.log('SIGNIN ERR', err)
            yield put(setLogged(false))
        }
    });
    
    //SIGN UP
    yield takeLatest(signUp, function*({payload}) {
        try { 
            const response: SignupResponse = yield call(xhr.post, `/user/${payload.address}/sign-up`);
            yield put(setAddress(response.data.payload.address));
            yield put(setNonce(response.data.payload.nonce));
           
        } catch(err) {
            console.log('SIGNUP ERROR ', err)
        }
    })

}