import React, { useEffect, useState } from 'react';
import { Switch, Route, Router} from "react-router-dom";
import Navbar from '../components/navbar/index';
import { useAppSelector } from '../redux/hooks';
import { useWeb3 } from '../context/web3/hooks';
import { useCookies } from 'react-cookie';
import { CookieName } from '../config/cookie/index';
import { ChainIds } from '../config/chainids';
import EduDialog from '../components/dialog';
import locale from '../config/languages/locale';
import Home from "./home";
import Profile from './profile';
import { getNonce, userExists, signIn, signUp, setFromCookie } from '../redux/slices/auth';
import { useDispatch } from 'react-redux';
import ClassDetail from './class-detail';
import { AppRoute } from '../models/app-route/AppRoute';
import { ThemeProvider } from '@material-ui/core';
import { theme } from '../config/styles/theme';
import history from '../config/routing/history';

const App: React.FC = () => {
    
    const dispatch = useDispatch();
    const web3 = useWeb3();
    const auth = useAppSelector(state => state.auth);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentAccount, setCurrentAccount] = useState<string>(' ');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [cookies, setCookie] = useCookies();
    
    const toggleDialog = () => {
        setDialogOpen(!dialogOpen);
    };
    
    const logIn = async (nonce: string, address: string) => {
        const signature = await web3.sign(nonce);
        dispatch(signIn({ address, signature }));
    };

    const metamaskConnect = async () => {
        web3.getChainId()
            .then((res) => {
                if(!Object.values(ChainIds).includes(res)) {
                    toggleDialog();  
                }
                if(auth.exists === false) dispatch(signUp({address:currentAccount}));
                if(auth.exists === true) dispatch(getNonce({address: currentAccount}));
            })
            .catch(err => console.log('GET CHAIN ID ERROR: ', err))
    };

    //POST NONCE LOGIN
    useEffect(() => {
        if (auth.nonce && auth.address) {
            //doesn't trigger if user has been retrieved from cookie
            if(!auth.logged){
                logIn(auth.nonce.toString(), auth.address)
            }
        };
    }, [auth.nonce, auth.address, auth.logged]);

    //SAVE USER IN COOKIE
    useEffect(() => {
        if(auth.jwt){
            setCookie(CookieName.COOKIE, auth);
        }
    }, [auth.jwt, auth, setCookie])

    //INIT CHECKS
    useEffect(() => {
        if (loading) {
            //RETRIEVE USER FROM COOKIE
            if(cookies[CookieName.COOKIE]){
                dispatch(setFromCookie(cookies[CookieName.COOKIE]))
            };
            //GET WALLET INFO
            web3.getCurrentAccount()
            .then((res: string) => {
                setCurrentAccount(res);
                dispatch(userExists({ currentAddress: res }));
            })
            .catch(err => console.log('GET CURRENT ADDRESS ERROR: ', err));
            setLoading(false);
        }
    }, [loading, cookies, dispatch, web3]);

    //TODO: redirect to home
    const onLogoClick = () => {
        console.log('logo clicked')
    };
    

    return <>
        <ThemeProvider theme={theme} >
        <EduDialog
            maxWidth='md'
            open={dialogOpen}
            title={locale.loginDialog.title}
            body={locale.loginDialog.body}
            confirmText={locale.loginDialog.confirmText}
            onConfirm={toggleDialog}
            onClose={() => setDialogOpen(false)}
        />

         <Router history={history}>
            <Navbar 
                auth={auth} 
                onMetamaskConnect={metamaskConnect} 
                onLogoClick={onLogoClick}
                />
                
            {!loading && <Switch>
                <Route exact path={AppRoute.HOME} component={Home} />
                <Route path={AppRoute.PROFILE} component={Profile} exact={true}>
                    {/* <Route path={`${AppRoute.CLASS_DETAIL}/:id`} component={ClassDetail} exact={true} /> */}
                </Route>
                <Route path={`${AppRoute.PROFILE}${AppRoute.CLASS_DETAIL}/:id`} component={ClassDetail} exact={true} />
                <Route path={`${AppRoute.CLASS_DETAIL}/:id`} component={ClassDetail} exact={true} />
            </Switch>}
        </Router>
        </ThemeProvider>
    </>
}
    ;

export default App;

