import {createSlice, PayloadAction, createAction} from '@reduxjs/toolkit';
import { TransactionRecord } from '../../../models/transaction';
import { useAppSelector } from '../../hooks';

export interface TransactionsState {
    transactions: any[]; //todo: fix
    isTransactionFinish: boolean;
}

const initialState: TransactionsState = {
    transactions: [],
    isTransactionFinish: false
}

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        setTransactions: (state, action: PayloadAction<any[]>) => {
            state.transactions = action.payload
        },
        isTransactionFinish: (state, action: PayloadAction<boolean>) => {
            state.isTransactionFinish = action.payload
        }
    }
})

export const postTransaction = createAction<TransactionRecord>('postTransaction');

export const  { setTransactions, isTransactionFinish } = transactionsSlice.actions;

export const useTransactions = () => useAppSelector(state => state.transactions)

export default transactionsSlice