import { makeStyles } from '@material-ui/core';

const useGlobalAppStyles = makeStyles((theme) => ({
    optionsLinks: {
        textDecoration: 'none',
        fontFamily: 'Roboto',
        color: 'black',
        paddingLeft: '15px'
    },
    buttons: {
        padding: '10px 20px',
        backgroundColor: '#B81714',
        color: 'white'
    },
    altButtons: {
        padding: '10px 20px',
        backgroundColor: '#e8e8e8',
        color: 'black'
    },
    uploadButton: {
        backgroundColor: '#B81714',

        color: 'white',
        '&:disabled': {
            opacity: '0.6',
            color: 'white'
        }
    },
    dialog: {
        borderRadius: '0',
        textAlign: 'justify',
    },
    dialogTitle: {
        display: 'flex',
        height: '20px',
        paddingTop: '3px',
        paddingRight: '0',
        backgroundColor: '#E19855',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
    dialogRow: {
        marginTop: '2%',
    },
    priceRow: {
        marginTop: '5%'
    },
    dialogActions: {
        paddingRight: '2%',
        paddingBottom: '2%'
    },
    chipRow: {
        marginRight: '10px'
    },
    chip: {
        color: 'white',
        backgroundColor: '#E19855'
    },
    mainTitles: {
        color: '#B81714'
    },
    altTitles: {
        color: 'white',
    },
    secondaryTitles: {
        color: '#E19855'
    },
    accentText: {
        color: '#B81714'
    },
    cards: {
        border: 'none',
        backgroundColor: '#f7f7f7',
        borderRadius: '0 0 15px 15px'
    },
    cardHeader: {
        color: 'black',
    },
    cardsBody: {
        height: '180px',
        padding: '3%',
        overflow: 'hidden'
    },
    cardImg: {
        width: '100%',
        height: 'auto',

    },
    cardsActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        fontWeight: 'bold',
        border: '2px solid black',
        borderRadius: '15px',
        fontFamily: 'Roboto'
    },
    priceBox: {

    },
    marginRight: {
        marginRight: 10
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: '5%'
    },
    marginBottom: {
        marginBottom: '5%'
    },
    currencyLogo: {
        width: '40px',
        height: '40px',
        verticalAlign: 'middle',
        marginLeft: '5px'
    },
    colorCurrencyLogo: {
        filter: 'hue-rotate(150deg) saturate(15) contrast(200%) brightness(0.5)',
        width: '40px',
        height: '40px',
        verticalAlign: 'middle',
        marginLeft: '5px'
    },
    loader: {
        width: '100%',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    loaderHeading: {
        marginBottom: '40px',
        color: '#B81714'
    },
    loaderCircle: {
        color: '#E19855'
    },
    profileCard: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '80vw',
        padding: '2%',
        marginTop: '5vh'
    },

    profileAvatarBox: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '10%'
    },
    profileAvatar: {
        minWidth: '200px',
        minHeight: '200px',
        maxWidth: '250px',
        maxHeight: '250px',
    },
    profileTextBox: {
        padding: '100px',
    },
    teachingButton: {
        backgroundColor: '#e8e8e8',
        borderRadius: '15px',
        marginTop: 10
    },
    profileContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '5vh',
    },

    profileContent: {
        display: 'flex',
        flexDirection: 'column',
    },

    userBio: {
        marginTop: '5%',
        color: '#4f4f4f'
    },
    profileHeading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    profileForm: {
        border: 'none',
        boxShadow: 'none'
        /* display: 'flex',
        flexDirection: 'column',
         */
    },
    profileInput: {
        marginBottom: '5%',
        marginTop: '1%'
    },
    profileSave: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '2%'
    },
    profileAvatarEdit: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#D87B20'
        },
        backgroundColor: '#B81714',
        border: '3px solid black'
    },
    greyFilter: {
        filter: 'opacity(0.5)'

    },
    courseFormBox: {
        width: '100%',
        height: 'auto'
    },
    formField: {
        margin: '10px !important',
    },
    courseImgPreview: {
        maxWidth: '50%',
        marginBottom: '20px',
        marginLeft: '25%'
    },
    uploadCourse: {
        width: '90%',
        marginLeft: '5%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    resourceContainer: {
        width: '90vw',
        minHeight: '100vh'
    },
    eduWarning: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10vh 10vw',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#e6e6e6',
        minHeight: '200px',
        borderRadius: '15px'
    },
    sectionForm: {
        padding: '3%',
        width: '100%',
        display: 'flex'
    },
    classContainer: {
        backgroundColor: 'teal',

    },
    overView: {
        width: '80%',
        marginLeft: '10%',
        marginTop: '10vh',
        marginBottom: '10vh'
    },
    overViewImg: {
        minWidth: 'unset',
        maxWidth: '90%',
        height: 'auto',
        display: 'flex',
        marginRight: '10%'
    },

    sections: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '1px solid grey',
        backgroundColor: '#e6e6e6',
        padding: '10px',
    },
    lessonsThumbnail: {
        width: '50px',
        height: 'auto',
        marginRight: '10px'
    },
    divider: {
        width: '80%',
        marginLeft: '10%'
    },
    lessonListContainer: {


    },
    lessonList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        border: '1px solid grey',
    },
    editButtonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    editButtonTitle: {
        display: 'flex',
        maxHeight: '40px',
        justifyContent: 'flex-end'
    },
    courseOwnerSelect: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10%',
        marginBottom: '10%',
    }



}));

export default useGlobalAppStyles;