import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../hooks';
import Pagination from '../../../models/pagination';
import EduTransaction, { OwnerSlot } from '../../../models/transaction';
import EduClass, { EduBasicInterface, EduClassLesson, EduClassPost, EduClassPublish, EduClassSection } from '../../../models/class';

export interface CoursesState {
    courses: EduTransaction[],
    totalItems: number,
    pagination: Pagination,
    classDetail?: Partial<EduClass>,
    categories: EduBasicInterface[],
    coursesPurchased: EduTransaction[],
    coursesUploaded: EduClass[],
    activeStep: number,
    selectedCourseOwners?: OwnerSlot[]
    lessonId?: number,
    isEdit: boolean,
    isPublished: boolean,
    isPublishedDone: boolean,
    isCourseCreated: boolean
}

const initialState: CoursesState = {
    courses: [],
    totalItems: 0,
    pagination: {
        start: 0,
        limit: 10,
        page: 1,
        totalItems: 0
    },
    categories: [],
    coursesPurchased: [],
    coursesUploaded: [],
    activeStep: 0,
    selectedCourseOwners: [],
    isEdit: false,
    isPublished: false,
    isPublishedDone: false,
    isCourseCreated: false
}

export const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setCourseOwners: (state, action: PayloadAction<OwnerSlot[]>) => {
            state.selectedCourseOwners = action.payload
        },
        setCourses: (state, action: PayloadAction<EduTransaction[]>) => {
            state.courses = action.payload
        },
        setTotalItems: (state, action: PayloadAction<number>) => {
            state.totalItems = action.payload
        },
        setPagination: (state, action: PayloadAction<Pagination>) => {
            state.pagination.page = action.payload.page
            state.pagination.limit = action.payload.limit
            state.pagination.start = action.payload.start
            state.pagination.totalItems = action.payload.totalItems
        },
        setCoursesDetail: (state, action: PayloadAction<Partial<EduClass>>) => {
            state.classDetail = action.payload
        },
        addSection: (state, action: PayloadAction<EduClassSection>) => {
            if (state.classDetail) {
                state.classDetail = {
                    ...state.classDetail,
                    classSections: [
                        ...state.classDetail.classSections ?? [],
                        action.payload
                    ]
                };
            }
        },
        addLesson: (state, action: PayloadAction<EduClassLesson>) => {
            if (state.classDetail?.classSections) {
                const id = action.payload.sectionId;
                console.log('ID', id);
                const classSection = state.classDetail.classSections.find(e => e.id === id);
                console.log('SECTION', JSON.stringify(classSection));
                if (classSection) {
                    classSection.lessons = [
                        ...classSection.lessons,
                        action.payload
                    ]
                }
            }
        },
        setCategories: (state, action: PayloadAction<EduBasicInterface[]>) => {
            state.categories = action.payload
        },
        setCoursesUploaded: (state, action: PayloadAction<EduClass[]>) => {
            state.coursesUploaded = action.payload
        },
        addCoursesUploaded: (state, action: PayloadAction<EduClass>) => {
            state.coursesUploaded = [
                ...state.coursesUploaded,
                action.payload
            ]
        },
        setCoursesPurchased: (state, action: PayloadAction<EduTransaction[]>) => {
            state.coursesPurchased = action.payload
        },
        setLessonId: (state, action: PayloadAction<number>) => {
            state.lessonId = action.payload
        },
        setIsEdit: (state, action: PayloadAction<boolean>) => {
            state.isEdit = action.payload
        },
        setIsPublished: (state, action: PayloadAction<boolean>) => {
            state.isPublished = action.payload
        },
        setIsPublishedDone: (state, action: PayloadAction<boolean>) => {
            if (state.classDetail) {
                state.classDetail = {
                    ...state.classDetail,
                    public: action.payload
                }
            }
        },
        setIsCourseCreated: (state, action: PayloadAction<boolean>) => {
            state.isCourseCreated = action.payload
        },
        reset: (state) => {
            state.courses = initialState.courses
            state.pagination = initialState.pagination
            state.totalItems = initialState.totalItems
        }
    },
})

export const getCourses = createAction<{ pagination: Pagination }>('getCourses');
export const getCoursesDetail = createAction<{ classId: number }>('getCoursesDetail')
export const getCategories = createAction('getCategories');
export const getCourseOwners = createAction<number>('getSellers');
export const postCourse = createAction<EduClassPost>('postCourse');
export const patchCourse = createAction<EduClassPost>('patchCourse');
export const postSection = createAction<{ section: { name: string, description: string }, classId?: number }>('postSection');
export const postLesson = createAction<{ lesson: { name: string, description: string }, sectionId: number }>('postLesson');
export const postVideo = createAction<{ video?: FormData, lessonId?: number }>('postVideo');
export const getCoursesUploaded = createAction('getCoursesUploaded');
export const getCoursesPurchased = createAction('getCoursesPurchased');
export const publishCourse = createAction<EduClassPublish>('publishCourse');
export const {
    setCourseOwners,
    setCourses,
    setTotalItems,
    reset,
    setPagination,
    setCategories,
    setCoursesUploaded,
    addCoursesUploaded,
    setCoursesPurchased,
    setLessonId,
    setCoursesDetail,
    addSection,
    addLesson,
    setIsEdit,
    setIsPublished,
    setIsPublishedDone,
    setIsCourseCreated
} = coursesSlice.actions;

export const useResources = () => useAppSelector(state => state.courses)

export default coursesSlice

