import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';

import 'normalize.css';

import App from "./pages";

import {Provider as ReduxProvider} from "react-redux";
import {store} from "./redux/store";
import {Web3Provider} from './context/web3/hooks';
import {Web3ProviderInstance} from "./context/web3";

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <Web3Provider value={Web3ProviderInstance}>
                <App/>
            </Web3Provider>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
