import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from "@material-ui/core";
import locale from '../../config/languages/locale';
import ResourceCard from '../../components/resource-card';
import ResourceDialog from '../../components/resource-dialog';
import EduTransaction from '../../models/transaction';
import useHomeStyles from './style';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCategories, getCourses, setPagination } from '../../redux/slices/courses';
import EduLoader from '../../components/loader';
import { useAuth } from '../../redux/slices/auth';
import { updatePagination } from '../../config/pagination';
import Pagination from '@material-ui/lab/Pagination';
import { setAuthToken } from '../../api';

const Home: React.FC = () => {

    const classes = useHomeStyles();
    const auth = useAuth();
    const dispatch = useAppDispatch();
    const availableCourses = useAppSelector(state => state.courses.courses);
    const pagination = useAppSelector(state => state.courses.pagination);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [detailCourse, setDetailCourse] = useState<EduTransaction>();
    const isLoading = useAppSelector(state => state.loader.isLoading);


    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const expandResource = (resource: EduTransaction) => {
        setDetailCourse(resource);
        toggleModal();
    };
    //set headers token after login
    useEffect(() => {
        if (auth.jwt) {
            setAuthToken(auth.jwt).then(
                res => {
                    setLoading(true);
                    dispatch(getCourses({ pagination }));
                    dispatch(getCategories());
                }
            )
                .catch(err => console.log(err))
        }
        //check if dispatch dep fucks things up
    }, [auth.jwt, pagination, dispatch]);



    useEffect(() => {
        setLoading(false);
    }, [availableCourses]);


    const onPageChange = (page: number) => {
        const updated = updatePagination({ ...pagination, page: page });
        dispatch(setPagination(updated));
        setLoading(true);
        dispatch(getCourses({ pagination: updated }));
    };

    return (
        <>

            {!auth.jwt &&
                <Box textAlign={'center'} marginTop={8}>
                    <Typography variant={"body2"}>
                        {locale.shared.unconnected}
                    </Typography>
                </Box>
            }
            {auth.jwt && !loading &&
                <>
                    <Box sx={{ mt: 5, ml: 3 }}>

                        <Typography variant='h5' className={classes.secondaryText}>
                            {locale.shared.connected}
                        </Typography>
                        <Typography variant='h3' className={classes.emphatizedText}>
                            {locale.shared.start}
                        </Typography>

                    </Box>
                    {isLoading ? <EduLoader label='loading'></EduLoader> : <Box>

                        <Grid
                            className={classes.resourcesContainer}
                            container
                            spacing={6}
                        >
                            {loading && <EduLoader label='Loading' />}

                            {availableCourses?.map((entry: EduTransaction) => (


                                <Grid item xs={12} sm={6} md={4} lg={3} key={`${entry.class.info?.title}-resource-card`}>
                                    <ResourceCard
                                        classId={entry.class.id}
                                        resource={entry}
                                        isEdit={false}
                                        onClick={() => expandResource(entry)}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Pagination
                            shape='rounded'
                            count={pagination.totalItems / pagination.limit}
                            boundaryCount={2}
                            showFirstButton={true}
                            showLastButton={true}
                            page={pagination.page}
                            onChange={(e: any, page: number) => onPageChange(page)}
                        />
                    </Box>}

                    <ResourceDialog
                        resource={detailCourse}
                        open={modalOpen}
                        onClose={toggleModal}
                        onBuy={() => console.log('clicked buy')}
                    />
                </>
            }

        </>

    );
};

export default Home;