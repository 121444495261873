import Web3 from "web3";
import React, {createContext} from "react";
export class EduWeb3Provider {

    private readonly instance: Web3
    private readonly context: React.Context<Web3>

    constructor() {
        this.instance = new Web3(Web3.givenProvider)
        this.context = createContext<Web3>(this.instance)
    }

    public async getCurrentAccount(): Promise<string> {
        return (await this.instance.eth.requestAccounts())[0]
    }

    public async sign(nonce: string): Promise<string> {
        const address = await this.getCurrentAccount();
        return this.signWith(nonce, address)
    }
    //get info on current id
    public async getChainId(): Promise<number> {
        return await this.instance.eth.getChainId()
    }

    public async signWith(nonce: string, address: string): Promise<string> {
        return (await this.instance.eth.personal.sign(nonce, address, nonce))
    } 

    public toWei(numberString: string) {
        return this.instance.utils.toWei(numberString, 'ether')    
    }

    public fromWei(numberString: string) {
        return this.instance.utils.fromWei(numberString, 'ether')
    }

    public async sendTransaction(seller: string, buyer: string, value: string) {
        return this.instance.eth.sendTransaction({
            to: seller,
            from: buyer,
            value: value,
        }, (error: Error, hash: string) => {
            console.log('hash', hash)
        }) 
    }
}
