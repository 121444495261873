import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, TextField } from '@material-ui/core';
import useGlobalAppStyles from '../../config/styles';
import locale from '../../config/languages/locale';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { postVideo } from '../../redux/slices/courses';
import EduLoader from '../loader';


interface HorizontalLabelPositionBelowStepperProp {
    handleSection: (e: any) => void
    onAdd: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2)
        },
    }),
);

function getSteps() {
    return ['Create a lesson', 'Upload a lesson', 'Finish'];
}


export default function HorizontalLabelPositionBelowStepper(prop: HorizontalLabelPositionBelowStepperProp) {
    const { handleSection, onAdd } = prop;

    const [file, setFile] = useState<FormData>()

    const classes = useStyles();
    const styles = useGlobalAppStyles();
    const steps = getSteps();
    const activeStep: number = useAppSelector(state => state.stepper.activeStep);
    const lessonId: number | undefined = useAppSelector(state => state.courses.lessonId);
    const isLoading: boolean = useAppSelector(state => state.loader.isLoading);
    const dispatch = useAppDispatch();

    /*     useEffect(() => {
            if (file === undefined) {
                setLoad(false)
            };
            setLoad(true);
        }, [file])
     */
    const handleNext = () => {
        onAdd();
    };

    const handleFinish = (file?: FormData) => {
        //dispatch(postVideo());
        const payload = { video: file, lessonId: lessonId };
        file && lessonId ? dispatch(postVideo(payload)) : alert("You haven't uploaded a file");
    };

    const handleUpload = (e: any) => {
        const fileUploaded: File = e.target.files[0];
        const formData = new FormData();
        formData.append('file', fileUploaded);
        //const formDataFile = formData.get('file') as FormDataEntryValue
        setFile(formData);
    }

    /*     const handleBack = () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };
    
        const handleReset = () => {
            setActiveStep(0);
        };
     */
    function getStepContent(stepIndex: number) {
        switch (stepIndex) {
            case 0:
                return <form onChange={handleSection}>
                    {/* isLoading ? <Box><EduLoader label='loading'></EduLoader></Box> :  */<Grid container spacing={3}>

                        <Grid item xs={6}>
                            <Typography variant='body1' className={styles.mainTitles}>{locale.shared.name}</Typography>
                            <TextField
                                type='text'
                                name='name'
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant='body1' className={styles.mainTitles}>{locale.shared.description}</Typography>
                            <TextField
                                type='text'
                                name='description'
                            />
                        </Grid>
                    </Grid>}
                </form>;
            case 1:
                return <form onChange={handleSection}>
                    {/* isLoading ? <Box><EduLoader label='loading'></EduLoader></Box> :  */<Box style={{ justifyContent: 'center', display: "flex" }}>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            Select Video
                            <input
                                type="file"
                                id="fileVideo"
                                onChange={(e) => handleUpload(e)}
                                hidden
                            />
                        </Button>
                        <Typography>{file ? 'Ready to upload' : 'Waiting your file'}</Typography>
                    </Box>}
                </form>;
            case 2:
                return <Box style={{ justifyContent: 'center', display: "flex" }}>
                    <Typography>File uploaded, thank you!</Typography>
                </Box>
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <div>
                    <Box className={classes.instructions}>{getStepContent(activeStep)}</Box>
                    <div className={styles.editButtonDiv}>
                        {/*                         <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.backButton}
                        >
                            Back
                        </Button>
 */}                    {(activeStep !== 2) && <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => (activeStep === 1 ? handleFinish(file) : handleNext())}>
                            {activeStep === 1 ? 'Upload' : 'Next'}
                        </Button>}
                    </div>
                </div>
            </div>
        </div>
    );
}