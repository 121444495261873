import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { useEffect } from 'react';
import useGlobalAppStyles from '../../config/styles';
import { useAppDispatch, useAppSelector} from '../../redux/hooks';
import { resetActiveStep } from '../../redux/slices/stepper';
import EduLoader from '../loader';

export interface EduDialogProps {
    open: boolean;
    title: string;
    body?: string;
    confirmText?: string;
    onConfirm?: (e: any) => void;
    customContent?: JSX.Element;
    maxWidth: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
    fullWidth?: boolean,
    onClose: () => void,
    fullScreen?: boolean
}

const EduDialog = (props: EduDialogProps) => {
    const {
        open,
        title,
        body,
        confirmText,
        onConfirm,
        onClose,
        customContent,
        maxWidth,
        fullWidth,
        fullScreen
    } = props;

    const styles = useGlobalAppStyles();
    const isModalLoading: boolean = useAppSelector(state => state.loader.isModalLoading);
    const dispatch = useAppDispatch();
    
    //const currentStep = useAppSelector(state => state.courses.activeStep)
    useEffect(() => {
        if (open) {
            return () => {
                dispatch(resetActiveStep());
            };
        }
    }, [open, dispatch])

    return <>
        <Dialog
            open={open}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            scroll='body'
            onClose={onClose}
            fullScreen={fullScreen}
        >
            <DialogTitle className={`${styles.dialogTitle} ${styles.altTitles}`}>{title} </DialogTitle>

            <DialogContent>

                {body && <Typography variant='body1'>{body}</Typography>}
                {isModalLoading ? <EduLoader></EduLoader> : customContent}
            </DialogContent>

            <DialogActions>
                {onConfirm && confirmText &&
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={onConfirm}
                        className={styles.buttons}
                    >
                        {confirmText}
                    </Button>
                }
            </DialogActions>

        </Dialog>
    </>
}

export default EduDialog;