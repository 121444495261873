import { call, put, select, takeLatest } from 'redux-saga/effects';
import Pagination from '../../../models/pagination';
import { EduResponse, xhr } from '../../../api';
import { EndPoints } from '../../../config/api-endpoints';
import {
    publishCourse, patchCourse, postSection, getCourses, setCourses,
    setTotalItems, setPagination, getCategories, setCategories,
    getCoursesUploaded, setCoursesUploaded, postCourse,
    getCoursesPurchased, setCoursesPurchased, postLesson, setLessonId, postVideo, getCoursesDetail, setCoursesDetail, addSection, getCourseOwners, setCourseOwners, addLesson, setIsPublished, setIsPublishedDone, setIsCourseCreated, addCoursesUploaded
} from '../../slices/courses';
import EduTransaction from '../../../models/transaction';
import EduClass, { EduClassLesson, EduClassSection } from '../../../models/class';
import mock from '../../../assets/mocked-data/categories.json';
import { resetActiveStep, setActiveStep } from '../../slices/stepper';
import { AddLessonServerResponse } from '../../../models/server-response';
//import { AppRoute } from '../../../models/app-route/AppRoute';
//import history from '../../../config/routing/history';
import { setIsLoading, setIsModalLoading } from '../../slices/loader';
import { OwnerSlot } from '../../../models/transaction';


type ResourceResponse = {
    data: EduResponse<{ totalItems: number, data: EduTransaction[] }>
}

type CoursesUploadedResponse = {
    data: EduResponse<{ totalItems: number, data: EduClass[] }>
}

type CoursesPurchasedResponse = {
    data: EduResponse<{ totalItems: number, data: EduTransaction[] }>
}
/* 
type CategoriesResponse = {
    data: EduResponse<{ totalItems: number, data: EduBasicInterface[] }>
} */

type PostCourseResponse = {
    data: EduResponse<{ data: EduClass }>
}

type AddLessonResponse = {
    data: EduResponse<{ totalItems: number, data: AddLessonServerResponse }>
}

type ClassDetailResponse = {
    data: EduResponse<{ data: Partial<EduClass> }>
}

type SectionResponse = {
    data: EduResponse<{ data: EduClassSection }>
}

type CourseOwnersResponse = {
    data: EduResponse<{ data: OwnerSlot[] }>
}

export default function* resourcesSagas() {

    yield takeLatest(getCourses, function* ({ payload }) {
        try {
            yield put(setIsLoading(true));
            const pagination: Pagination = payload.pagination;
            const queryParams = new URLSearchParams({
                start: payload.pagination.start.toString(),
                limit: payload.pagination.limit.toString()
            });
            const response: ResourceResponse = yield call(xhr.get, EndPoints.GET_RESOURCES, { params: queryParams });
            const courses: EduTransaction[] = response.data.payload.data
            const items = response.data.payload.totalItems;
            yield put(setCourses(courses));
            yield put(setTotalItems(items));
            yield put(setPagination({ ...pagination, totalItems: items }));
            yield put(setIsLoading(false));
        } catch (err) {
            console.log('GET RESOURCE ERROR', err)
        }
    });

    yield takeLatest(getCoursesDetail, function* ({ payload }) {
        try {
            yield put(setIsLoading(true));
            const classId: number = payload.classId
            const response: ClassDetailResponse = yield call(xhr.get, `/resources/${classId}`);
            const classDetail: Partial<EduClass> = response.data.payload.data;
            yield put(setCoursesDetail(classDetail));
            yield put(setIsLoading(false));
            //history.push(`${AppRoute.CLASS_DETAIL}/${classId}`);
        } catch (err) {
            console.log('GET COURSES DETAIL ERROR', err)
        }
    });

    yield takeLatest(getCategories, function* ({ payload }) {
        try {
            //const response: CategoriesResponse = yield call(xhr.get, EndPoints.GET_CATEGORIES);
            // eslint-disable-next-line
            const response = mock;
            const categories = mock.payload.data;
            yield put(setCategories(categories));
        } catch (err) {
            console.log('GET CATEGORIES ERROR', err)
        }
    });

    //POST COURSE
    yield takeLatest(postCourse, function* ({ payload }) {
        try {
            yield put(setIsModalLoading(true));
            const response: PostCourseResponse = yield call(xhr.post, EndPoints.POST_RESOURCES, payload);
            const newCourse: EduClass = response.data.payload.data;
            yield put(addCoursesUploaded(newCourse));
            yield put(setIsCourseCreated(true));
            yield put(setIsModalLoading(false));
        } catch (err) {
            console.log('POST COURSE ERROR', err)
            yield put(setIsModalLoading(false));
        }
    });

    //PATCH COURSE
    yield takeLatest(patchCourse, function* ({ payload }) {
        try {
            // eslint-disable-next-line
            //const response: PatchCourseResponse = yield call(xhr.patch, EndPoints.PATCH_COURSE, payload);
            //should patch locally stored copy of course here
        } catch (err) {
            console.log('PATCH COURSE ERR', err)
        }
    });

    //POST SECTION
    yield takeLatest(postSection, function* ({ payload }) {
        try {
            yield put(setIsModalLoading(true));
            const section = payload.section;
            const id = payload.classId;
            const response: SectionResponse = yield call(xhr.post, `/resources/${id}/add-section`, section);
            /* const oldSection: EduClassSection[] = yield select(state => state.courses.sections); */
            const newSection: EduClassSection = response.data.payload.data;
            yield put(addSection(newSection));
            yield put(setIsModalLoading(false));
            //should update locally stored copy
        } catch (err) {
            console.log('POST SECTION ERR')
            yield put(setIsModalLoading(false));
        }
    })

    yield takeLatest(postLesson, function* ({ payload }) {
        try {
            yield put(setIsModalLoading(true));
            const lesson = payload.lesson;
            const id = payload.sectionId;
            const response: AddLessonResponse = yield call(xhr.post, `/resources/sections/${id}/add-lesson`, lesson);
            yield put(resetActiveStep());
            const currentStep: number = yield select(state => state.courses.activeStep);
            const lessonId: number = response.data.payload.data.id;
            const newLesson: EduClassLesson = response.data.payload.data;
            yield put(setActiveStep(currentStep + 1));
            yield put(setLessonId(lessonId));
            yield put(setIsModalLoading(false));
            yield put(addLesson(newLesson));
            //should update locally stored copy
        } catch (err) {
            console.log('POST LESSON ERR')
            yield put(setIsModalLoading(false));
        }
    })

    yield takeLatest(postVideo, function* ({ payload }) {
        try {
            yield put(setIsModalLoading(true));
            const file = payload.video;
            const id = payload.lessonId;
            console.log(file)
            // eslint-disable-next-line 
            const response: EduResponse<any> = yield call(xhr.post, `/resources/lessons/${id}/upload-file`, file);
            yield put(resetActiveStep());
            const currentStep: number = yield select(state => state.courses.activeStep);
            yield put(setActiveStep(currentStep + 2));
            yield put(setIsModalLoading(false));
            //should update locally stored copy
        } catch (err) {
            console.log('POST VIDEO ERR')
            //added step for demo, remove in future
            const currentStep: number = yield select(state => state.courses.activeStep);
            yield put(setActiveStep(currentStep + 2));
            yield put(setIsModalLoading(false));
        }
    })

    yield takeLatest(getCoursesUploaded, function* ({ payload }) {
        try {
            yield put(setIsLoading(true));
            const address: string = yield select(state => state.auth.address);
            const response: CoursesUploadedResponse = yield call(xhr.get, `/user/${address}${EndPoints.CLASSES_UPLOADED}`);
            // const coursesUploaded: EduClass[] = mockTransactions.payload.data;
            const coursesUploaded: EduClass[] = response.data.payload.data;
            yield put(setCoursesUploaded(coursesUploaded));
            yield put(setIsLoading(false));
        } catch (err) {
            console.log('GET COURSES UPLOADED ERROR', err)
            yield put(setIsLoading(false));
        }
    });

    yield takeLatest(getCoursesPurchased, function* ({ payload }) {
        try {
            const address: string = yield select(state => state.auth.address);
            const response: CoursesPurchasedResponse = yield call(xhr.get, `/user/${address}${EndPoints.CLASSES_PURCHASED}`);
            // const coursesUploaded: EduClass[] = mockTransactions.payload.data;
            const coursesPurchased: EduTransaction[] = response.data.payload.data;
            yield put(setCoursesPurchased(coursesPurchased));
        } catch (err) {
            console.log('GET COURSES PURCHASED ERROR', err)
        }
    });

    //GET COURSE OWNERS
    yield takeLatest(getCourseOwners, function* ({ payload }) {

        try {

            const id = payload;
            const response: CourseOwnersResponse = yield call(xhr.get, `resources/${id}/sellers`);
            const owners = response.data.payload.data;
            yield put(setCourseOwners(owners));

        } catch (err) {
            console.log('GET OWNERS ERROR ', err);
        }
    })

    //PUBLISH COURSE
    yield takeLatest(publishCourse, function* ({ payload }) {
        try {
            yield put(setIsModalLoading(true));
            const body = {
                sellerId: payload.sellerId,
                price: payload.price,
                copies: payload.copies
            };
            //eslint-disable-next-line
            const response: EduResponse<any> = yield call(xhr.post, `resources/${payload.classId}/sell`, body); 
            yield put(setIsModalLoading(false));
            yield put(setIsPublished(true));
            yield put(setIsPublishedDone(true));
        } catch (err) {
            console.log('PUBLISH ERROR ', err)
            yield put(setIsModalLoading(false));
        }
    })


}
