import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, Button } from '@material-ui/core';
import useGlobalAppStyles from '../../config/styles';
import locale from '../../config/languages/locale';
import { Send } from '@material-ui/icons';

export type SectionFormProps = {
    onAdd: (e: any) => void
}
const SectionForm = (props: SectionFormProps) => {
    const { onAdd } = props;
    const styles = useGlobalAppStyles();
    const [section, setSection] = useState({
        name: '',
        description: '',
    });

    const handleSection = (e: any) => {
        setSection({
            ...section,
            [e.target.name]: e.target.value
        })
    };

    return <>
        <Box sx={{
            marginTop: '5vh',
            marginLeft: '1vw',
            marginBottom: '5vh',

        }}>

            <form onChange={handleSection}>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <Typography variant='body1' className={styles.mainTitles}>{locale.shared.name}</Typography>
                        <TextField
                            type='text'
                            name='name'

                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant='body1' className={styles.mainTitles}>{locale.shared.description}</Typography>
                        <TextField
                            type='text'
                            name='description'
                            multiline={true}
                        />
                    </Grid>

                </Grid>
            </form>
        </Box>
        
        <div className={styles.editButtonDiv}>
            <Button
                variant='contained'
                className={styles.buttons}
                onClick={(e: any) => onAdd(section)}
                startIcon={<Send />}
            >
                {locale.shared.send}
            </Button>
        </div>
    </>
}
export default SectionForm;