import React, { useState } from 'react';
import { Box, } from '@material-ui/core';
import HorizontalLabelPositionBelowStepper from '../stepper-linear';
import { useAppDispatch } from '../../redux/hooks';
import { postLesson } from '../../redux/slices/courses';

export type FormLesson = {
    name: string,
    description: string,
}

export type SectionFormProps = {
    onAdd: (form: FormLesson) => void,
    sectionId?: number
}
const LessonForm = (props: SectionFormProps) => {
    const { sectionId } = props;
    const [lesson, setLesson] = useState({
        name: '',
        description: '',
    });
    const dispatch = useAppDispatch();
    
    const addLesson = () => {
        if (sectionId) {
            dispatch(postLesson({ 
                lesson: { 
                    name: lesson.name, 
                    description: lesson.description 
                }, 
                sectionId: sectionId 
            }))
        }
    };


    const handleSection = (e: any) => {
        setLesson({
            ...lesson,
            [e.target.name]: e.target.value
        });
    };

    return <>
        <Box sx={{
            marginTop: '5vh',
            marginLeft: '1vw',
            marginBottom: '5vh',

        }}>
                <HorizontalLabelPositionBelowStepper onAdd={addLesson} handleSection={handleSection}/>
        </Box>
        
{/*         <div className={styles.editButtonDiv}>
            <Button
                variant='contained'
                className={styles.buttons}
                onClick={(e: any) => onAdd(lesson)}
                startIcon={<Send />}
            >
                {locale.shared.send}
            </Button>
        </div>
 */}    </>
}
export default LessonForm;