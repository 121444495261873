import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, Button, TextField, Grid } from '@material-ui/core';
import locale from '../../config/languages/locale';

import useGlobalAppStyles from '../../config/styles';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { useWeb3 } from '../../context/web3/hooks';

import { publishCourse, setIsPublished } from '../../redux/slices/courses';
import EduLoader from '../loader';



type PublishModalProps = {
    classId: number,
    onCloseDialog: () => void
}

const PublishModal = (props: PublishModalProps) => {

    const { classId, onCloseDialog } = props;

    //HOOKS
    const styles = useGlobalAppStyles();
    const dispatch = useAppDispatch();
    const userAddress = useAppSelector(state => state.auth.address);
    const web3 = useWeb3();

    //SELECTORS
    const isModalLoading: boolean = useAppSelector(state => state.loader.isModalLoading);
    const isPublished: boolean = useAppSelector(state => state.courses.isPublished);

    //LOCAL VARS
    const [copies, setCopies] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [disableButton, setDisableButton] = useState<boolean>(true);


    const publish = (e: any) => {
        dispatch(publishCourse({
            price: web3.toWei(price.toString()),
            copies: copies,
            sellerId: userAddress,
            classId: classId
        }))
    };

    //ENABLE SUBMIT BUTTON
    useEffect(() => {
        if (copies > 0 && price > 0) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [copies, price])

    const onClose = () => {
        onCloseDialog();
        dispatch(setIsPublished(false));
    }

    const handleSelling = () => {
        if (isPublished) {
            return <Box>
                <Typography>Your course has been published!</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5%'
                }}>
                    <Button
                        variant='outlined'
                        className={styles.buttons}
                        onClick={onClose}
                    >
                        {locale.shared.finish}
                    </Button>
                </Box>
            </Box>
        } else {
            return <Box sx={{ padding: '2% 5%' }}>

                <Box sx={{ marginBottom: '10%' }}>
                    <Typography variant='h6'>{locale.classPublish.title}</Typography>
                    <Typography variant='body1'>{locale.classPublish.body1}</Typography>
                    <Typography variant='body1'>{locale.classPublish.body2}</Typography>
                </Box>

                <Grid container>

                    <Grid item xs={6}>
                        <TextField
                            label={locale.classPublish.priceLabel}
                            key='copy-price'
                            variant='outlined'
                            name='price'
                            type='number'
                            value={price}
                            onChange={(e: any) => setPrice(parseFloat(e.target.value))}
                            error={price <= 0}
                            required
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl>
                            <TextField
                                label={locale.classPublish.copiesLabel}
                                key='course-copies'
                                variant='outlined'
                                name='copies'
                                type='number'
                                value={copies}
                                onChange={(e: any) => setCopies(parseInt(e.target.value))}
                                error={copies <= 0}
                                required
                            />
                        </FormControl>
                    </Grid>

                </Grid>

                <Box sx={{ marginTop: '10%' }}>
                    <Typography variant='caption'> {locale.classPublish.disclaimer} </Typography>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5%'
                }}
                >
                    <Button
                        variant='outlined'
                        className={styles.buttons}
                        onClick={publish}
                        disabled={disableButton}
                    >
                        {locale.classDetail.publish}
                    </Button>
                </Box>
            </Box>
        }
    }

    return <>
        {isModalLoading ? <EduLoader label='selling'></EduLoader> : handleSelling()}
    </>
}

export default PublishModal;