import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { useAppSelector } from '../../hooks';

export interface StepperState {
    activeStep: number
}

const initialState: StepperState = {
    activeStep: 0
}

export const stepperSlice = createSlice({
    name: 'stepper',
    initialState,
    reducers: {
        setActiveStep: (state, action: PayloadAction<number> ) => {
            state.activeStep = action.payload
        },
        resetActiveStep: (state) => {
            state.activeStep = initialState.activeStep
        },
    },
})

export const { setActiveStep, resetActiveStep } = stepperSlice.actions;

export const useResources = () => useAppSelector(state => state.stepper)

export default stepperSlice

