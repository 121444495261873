import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { useAppSelector } from '../../hooks';

export interface LoaderState {
    isLoading: boolean,
    isModalLoading: boolean
}

const initialState: LoaderState = {
    isLoading: false,
    isModalLoading: false
}

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean> ) => {
            state.isLoading = action.payload
        },
        setIsModalLoading: (state, action: PayloadAction<boolean> ) => {
            state.isModalLoading = action.payload
        },
        resetLoader: (state) => {
            state.isLoading = initialState.isLoading
        },
    },
})

export const { setIsLoading, setIsModalLoading, resetLoader } = loaderSlice.actions;

export const useResources = () => useAppSelector(state => state.stepper)

export default loaderSlice

