import React, { useEffect, useState } from 'react'
import { FormControl, TextField, FormGroup, Box, Button, Switch, FormControlLabel, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import { Send, Publish, Replay } from '@material-ui/icons';
import useGlobalAppStyles from '../../config/styles';
import locale from '../../config/languages/locale';
import EduClass from '../../models/class';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import EduLoader from '../loader';
import { setIsCourseCreated } from '../../redux/slices/courses';


export type CourseFormProps = {
    categories: string[];
    editCourse?: Partial<EduClass>;
    edit?: boolean;
    onCloseDialog: () => void;
    onAdd: (e: any) => void;
    onPatch?: (e: any) => void;
};

const CourseForm = (props: CourseFormProps) => {
    const { categories, editCourse, edit, onAdd, onPatch, onCloseDialog } = props;

    const [, setFile] = useState<File>();
    const [fileUrl, setFileUrl] = useState<any>();
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [form, setForm] = useState<{ [key: string]: any }>({
        //cover: 'asdasdsad',
        title: '',
        description: '',
        category: {
            name: ''
        },
    });

    const styles = useGlobalAppStyles();
    const dispatch = useAppDispatch();

    const isModalLoading: boolean = useAppSelector(state => state.loader.isModalLoading);
    const isCourseCreated: boolean = useAppSelector(state => state.courses.isCourseCreated);

    const handleChange = (e: any) => {
        if (e.target.files) {
            setFile((e.target.files[0]));
            setFileUrl(URL.createObjectURL(e.target.files[0]));
            //TODO : file upload
        } else if (e.target.name === 'category') {
            setForm({
                ...form,
                category: { name: e.target.value }
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            });
        }
    };

    //Enables submit button
    useEffect(() => {
        const controlFields: string[] = ['title', 'description'];

        if (form.category.name) {
            if (controlFields.every(entry => !!form[entry])) setAllowSubmit(true);
            else setAllowSubmit(false);
        } else {
            setAllowSubmit(false);
        }
    }, [form]);

    useEffect(() => {
        if (editCourse) {
            console.log('found edit, patching')
            setForm({
                title: editCourse.info?.title || '',
                description: editCourse.info?.description || '',
                category: {
                    name: editCourse.info?.category.name
                },
            });
        }

    }, [editCourse])

    const onClose = () => {
        onCloseDialog();
        dispatch(setIsCourseCreated(false));
    }

    const handleCreateCourse = () => {
        if (isCourseCreated) {
            return <Box>
                <Typography>{locale.createCourse.successful}</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5%'
                }}>
                    <Button
                        variant='outlined'
                        className={styles.buttons}
                        onClick={onClose}
                    >
                        {locale.shared.finish}
                    </Button>
                </Box>
            </Box>
        } else {
            return <Box>
                <Box className={styles.courseFormBox}>

                    <FormGroup>

                        <FormControl className={styles.formField} onChange={handleChange}>

                            {fileUrl && <img alt='course-cover' className={`${styles.courseImgPreview}`} src={fileUrl} />}

                            <input
                                id='img-upload'
                                accept='image/*'
                                style={{ display: 'none' }}
                                type='file'
                            />
                            <label htmlFor='img-upload'>
                                <Button
                                    variant='contained'
                                    component='span'
                                    endIcon={fileUrl ? <Replay /> : <Publish />}
                                >
                                    {fileUrl ? locale.shared.change : locale.shared.upload}
                                </Button>
                            </label>

                        </FormControl>

                        <FormControl className={styles.formField}>
                            <TextField
                                label='Title'
                                key='class-info-title'
                                variant='outlined'
                                name='title'
                                type='text'
                                required
                                value={form.title}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl className={styles.formField}>
                            <TextField
                                label='Description'
                                multiline={true}
                                key='class-info-description'
                                variant='outlined'
                                name='description'
                                type='text'
                                required
                                value={form.description}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}

                            />
                        </FormControl>

                        <FormControl className={styles.formField}>
                            <Autocomplete
                                freeSolo
                                options={categories}
                                value={form.category.name}
                                onChange={(e: any, v: any) => { handleChange({ target: { value: v, name: 'category' } }) }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name='category'
                                        label='Category'
                                        variant='outlined'
                                        onChange={handleChange}
                                        required
                                    />
                                }
                            />
                        </FormControl>


                    </FormGroup>
                    <Box className={styles.uploadCourse}>
                        <Button
                            endIcon={<Send />}
                            className={styles.uploadButton}
                            variant='outlined'
                            onClick={(edit && onPatch) ? (e: any) => onPatch(form) : (e: any) => onAdd(form)}
                            disabled={!allowSubmit}
                        >
                            {locale.shared.send}
                        </Button>
                    </Box>


                </Box>
            </Box>
        }
    }

    return <>
        {isModalLoading ? <EduLoader></EduLoader> : handleCreateCourse()}
    </>
};


export default CourseForm