import { Alert } from '@material-ui/lab';
import { Button, Box } from '@material-ui/core';

import locale from '../../config/languages/locale';

type DetailAlertProps = {
    complete: boolean,
    publish: (e: any) => void
}

const DetailAlert = (props: DetailAlertProps) => {
    const {complete, publish} = props;

    return <>
    <Box sx={{margin: '5vh 10vw'}}>
        
        <Alert 
            color={complete ? 'success' : 'warning'}
            severity={complete ? 'success': 'warning'}
            action={complete && 
                <Button     
                    color='inherit' 
                    size='small'
                    onClick={publish}
                >
                    {locale.classDetail.publish}
                </Button>
            }
        >
             {complete ? locale.classDetail.canPublish : locale.classDetail.cantPublish}
        </Alert>
    </Box>
    </>
}

export default DetailAlert;