import { EduClassLesson, EduClassSection } from "../../models/class"
import { List, ListItem, IconButton, Typography, Grid, Button } from '@material-ui/core';
import { Add, Launch } from '@material-ui/icons';
import useGlobalAppStyles from "../../config/styles";
import locale from '../../config/languages/locale';
import { useAppSelector } from "../../redux/hooks";
import { useState } from "react";

export type LessonListProps = {
    isEdit: boolean;
    section: EduClassSection;
    lessons: EduClassLesson[];
    onSectionId: (value: EduClassSection) => void;
}
const LessonList = (props: LessonListProps) => {
    const { lessons, section, isEdit, onSectionId } = props;

    const styles = useGlobalAppStyles();

    return <>
        <Grid container>
            <Grid item xs={6}>
                <Typography
                    variant='body1'
                    className={styles.mainTitles}>
                    {locale.shared.lessons}
                </Typography>
            </Grid>
            <Grid item xs={6} className={styles.editButtonTitle}>
                {isEdit && <Button
                    color='primary'
                    variant='contained'
                    onClick={() => onSectionId(section)}
                >
                    <Add />
                </Button>}
            </Grid>
        </Grid>


        {lessons?.length > 0 && lessons.map(entry =>

            <List key={`${entry.name}-list-entry`}>

                <ListItem className={styles.lessonList}>

                    <img alt='lesson' src={entry.thumbnail} className={styles.lessonsThumbnail} />

                    <Typography variant='h6'>{entry.name}</Typography>

                    <IconButton>
                        <Launch />
                    </IconButton>

                </ListItem>

            </List>
        )}

        {lessons?.length === 0 &&
            <Typography variant='body1'>{locale.shared.nolessons}</Typography>
        }
    </>
}

export default LessonList;