import {makeStyles} from "@material-ui/core";

const useNavbarStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    connect: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textDecoration: 'none',
        color: 'white'
    },
    address: {
        textTransform: 'lowercase'
    },
    toolbar: {
        backgroundColor: '#D87C21'
    },
    hoover: {
        cursor: 'pointer'
    }
}));

export default useNavbarStyles;
