import { Box, Typography } from '@material-ui/core'
import useGlobalAppStyles from '../../config/styles';

export type EduWarningProps = {
    message: string
};

const EduWarning = (props: EduWarningProps) => {

    const { message } = props;
    const styles = useGlobalAppStyles();

    return <>
        <Box className={styles.eduWarning}>
            <Typography variant='h4' className={styles.mainTitles}>{message}</Typography>
        </Box>
    </>
};
export default EduWarning;