import {useContext} from "react";
import {Web3Context} from "../index";
import {EduWeb3Provider} from "../provider";

export const useWeb3 = (): EduWeb3Provider => {
    const context = useContext(Web3Context)
    if (context === undefined) {
        throw new Error('useWeb3() hook must be used within a provider')
    }
    return context
}

export const Web3Provider = Web3Context.Provider
