import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from '@redux-saga/core';
import CoursesReducer from '../slices/courses';
import rootSaga from '../saga';
import AuthReducer from '../slices/auth';
import StepperReducer from '../slices/stepper';
import TransactionsReducer from '../slices/transactions';
import LoaderReducer from '../slices/loader';


const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        [CoursesReducer.name]: CoursesReducer.reducer,
        [AuthReducer.name]: AuthReducer.reducer,
        [StepperReducer.name]: StepperReducer.reducer,
        [TransactionsReducer.name]: TransactionsReducer.reducer,
        [LoaderReducer.name]: LoaderReducer.reducer,
    },
    middleware: [sagaMiddleware]
   
})

sagaMiddleware.run(rootSaga)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
